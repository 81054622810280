* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  background-position-x: 0;
  background-position-y: 0;
  background-size: cover;
  background-image: url('../../assets/images/galaxy1.png');
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
}

div#loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

div#loadingSubtitle {
  align-self: flex-start;
}

.break {
  flex-basis: 100%;
  height: 0;
}

h1.Signup {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Uncut Sans";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;

  text-transform: uppercase;
  color: #ffffff;
}

h3.Signup {
  font-family: "Uncut Sans";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  margin-bottom:30px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.emailSignup{
  width: 300px;
  height: 50px;
  background: #FFFFFF;
  /* Light Grey */
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid gray;
  font-size: 16px;
  border-radius: 20px;
}

.sitename {
  width: 300px;
  height: 50px;
  background: #FFFFFF;
  margin-top: 20px;
  /* Light Grey */
  padding: 10px 20px;
  border: 1px solid gray;
  font-size: 16px;
  border-radius: 20px;
}

.email.Signup {
  background: #ffffff;
  /* Light Grey */
  border-color: gray;
  border-width: 1px;
  font-size: 20px;
  border-radius: 5px;
}

.sitename.Signup {
  height: 50px;
  width: 300px;
  background: #ffffff;
  /* Light Grey */
  border-color: gray;
  border-width: 1px;
  font-size: 20px;
  border-radius: 5px;
}

.emailcap {
  background-color: transparent;
  width: 100px;
  height: 50px;
  border-radius: 60px;
  border-color: transparent;
}

.emailcap.Signup {
  background-color: transparent;
  width: 100px;
  height: 50px;
  border-radius: 40px;
  border-color: transparent;
}

.blackbutton {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.fancybutton {
  padding: 10px 22px;
  border: none;
  color: grey;
  z-index: 1;
  top: 45px;
  background: white;
  position: relative;
  font-weight: 1000;
  font-family: "Uncut-Sans";
  transition: all 250ms;
  font-size: 1.1vw;
  overflow: hidden;
  width: fit-content;
}

.fancybutton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0px;
  background: grey;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 600ms;
}

.fancybutton.a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 5px 0px 0px 5px;
  background: grey;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.17);
  transition: all 600ms;
}

.fancybutton.c::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0px 5px 5px 0px;
  background: grey;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.17);
  transition: all 600ms;
}

.fancybutton:hover {
  color: #e8e8e8;
  text-decoration: underline;
}

.fancybutton:hover::before {
  width: 100%;
}

.fancybutton.a {
  left: 0%;
  border-radius: 5px 0px 0px 5px;
}

.fancybutton.c {
  left: 0%;
  border-radius: 0px 5px 5px 0px;
}

div#box {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.subtitle {
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: "Uncut Sans";
}

div#white {
  background-color: white;
  padding: 40px;
  height: 300px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.dropdown {
  visibility: hidden;
  width: fit-content;
  display: flex;
  direction: column;
}

.hoverme:hover .dropdown {
  visibility: visible;
}

.Email-reply{
  width: 380px;
  height: 300px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0.95;
  backdrop-filter: blur(7.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
  display: flex;
  padding-top: 30px;
}

.mail-logo{
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}