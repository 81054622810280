.Email-reply {
  width: 300px;
  height: 180px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0.95;
  backdrop-filter: blur(7.5px);
  border-radius: 34px;
  display: flex;
  padding-top: 30px;
}

.Er-top {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 80px;
}

.mail-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailsee {
  background-color: transparent;
  border-radius: 60px;
  border-color: transparent;
}

.blackbutton {
  width: 100%;
  height: 100%;
  background-color: transparent;
}